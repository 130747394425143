<template>
    <div class="card">
        <h5 class="mb-5 font-medium">
            {{ state === 'edit' ? 'Edit' : 'Tambah' }}
            Konten
        </h5>
        <form class="p-fluid pt-2" @submit.prevent="doSubmit()">
            <div class="field single-upload">
                <label class="text-black text-14 roboto-medium">
                    Banner *
                </label>
                <Skeleton height="158px" v-if="loading" />
                <single-upload-file 
                    @responseURL="setFileUrl"
                    :current_file="form.banner"
                    v-else>
                </single-upload-file>
                <label class="mt-2 text-12 text-black opacity-60">
                    Format file: PNG atau SVG
                </label>
            </div>
            <div class="field mb-4" >
                <label class="text-black text-14 roboto-medium">
                    Judul Konten *
                </label>
                <Skeleton height="41px" v-if="loading" />
                <InputText
                    type="text"
                    placeholder="Judul Konten"
                    v-model="form.title"
                    :disabled="form.loading"
                    v-else
                />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Tipe *
                </label>
                <Skeleton height="41px" v-if="loading" />
                <Dropdown
                    v-model="form.selected_type"
                    :options="catefories"
                    optionLabel="name"
                    placeholder="Pilih Tipe"
                    :disabled="form.loading"
                    v-else
                />
            </div>
            <template v-if="form.selected_type['name'] === 'PROMO'">
                <div class="field mb-4">
                    <label class="text-black text-14 roboto-medium">
                        Kode Promo *
                    </label>
                    <Skeleton height="41px" v-if="loading" />
                    <InputText
                        type="text"
                        placeholder="Kode Promo"
                        v-model="form.promo_code.value"
                        :class="{ 'p-invalid': is_duplicate_promo }"
                        :disabled="form.loading"
                        @keyup="is_duplicate_promo = false"
                        v-else
                    />
                </div>
                <div class="field mb-4">
                    <label class="text-black text-14 roboto-medium">
                        Tanggal Mulai *
                    </label>
                    <Skeleton height="41px" v-if="loading" />
                    <Calendar 
                        dateFormat="yy-mm-dd" 
                        v-model="form.start_date.value" 
                        :showTime="true" 
                        :stepMinute="10" 
                        :minDate="new Date()"
                        :disabled="form.loading"
                        @date-select="removeEndDate"
                        v-else
                    ></Calendar>
                </div>
                <div class="field mb-4">
                    <label class="text-black text-14 roboto-medium">
                        Tanggal Berakhir *
                    </label>
                    <Skeleton height="41px" v-if="loading" />
                    <Calendar
                        dateFormat="yy-mm-dd"
                        v-model="form.end_date.value"
                        :showTime="true"
                        :stepMinute="10"
                        :disabled="!form.start_date.value || form.loading"
                        :minDate="minDate"
                        v-else
                    ></Calendar>
                </div>
            </template>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Konten *
                </label>
                <Skeleton height="220px" v-if="loading" />
                <Editor
                    v-model="form.detail"
                    placeholder="Tulis di sini"
                    editorStyle="height: 220px"
                    :disabled="form.loading"
                    v-else
                />
            </div>
            <div class="field">
                <label class="text-black text-14 roboto-medium mb-3">
                    Publish
                </label>
                <Skeleton height="24px" width="42px" v-if="loading" />
                <div class="flex align-items-center" v-else>
                    <InputSwitch v-model="form.is_publish" :disabled="form.loading" />
                    <label class="text-black opacity-60 ml-2">
                        Publish
                    </label>
                </div>
            </div>
            <div class="text-right py-5">
                <Button label="Batal" @click="back()" class="btn-outline-primary mr-3 w-auto"/>
                <Button type="submit"
                    class="btn-primary text-white roboto-semibold font-bold justify-content-center w-auto px-3"
                    :disabled="isDisabled || form.loading || loading"
                >
                    <span v-if="!form.loading">Simpan</span>
                    <i class="pi pi-spin pi-spinner" v-else></i>
                </Button>
            </div>
        </form>
    </div>
</template>

<script>
import Editor from 'primevue/editor';
import SingleUploadFile from '@/components/SingleUploadFile.vue'

export default {
    name: '',
    components: {
        Editor, SingleUploadFile,
    },
    data() {
        return {
            moment: require('moment-timezone'),
            state: 'add',
            loading: false,
            id: null,
            minDate: new Date(),
            init_promo_code: '',
            form: {
                loading: false,
                banner: '',
                title: '',
                detail: '',
                type: '',
                promo_code: {
                    id: null,
                    value: '',
                },
                start_date: {
                    id: null,
                    value: '',
                },
                end_date: {
                    id: null,
                    value: '',
                },
                selected_type: {name: ''},
                is_publish: false,
                status: 1,
            },
            is_duplicate_promo: false,
            promo_codes: [],
            catefories: [
                {name: 'PROMO'},
                {name: 'TERM'},
                {name: 'PRIVACY'},
                {name: 'FAQ'},
            ],
        }
    },
    computed: {
        isDisabled() {
            let isPromo = this.form.selected_type['name'] === 'PROMO'
            return !this.form.banner ||
                !this.form.title ||
                !this.form.selected_type['name'] ||
                !this.form.detail ||
                (isPromo ? (!this.form.promo_code.value || !this.form.start_date.value || !this.form.end_date.value) : false)
        }
    },
    watch: {
        'form.start_date.value': function(newValue) {
            this.minDate = new Date(newValue)
        },
    },
    mounted () {
        let route = this.$route
        this.state = route.name === 'edit-content' ? 'edit' : 'add'
        this.id = route.name === 'edit-content' ? route.params.id : null
        
        this.getData()
    },
    methods: {
        back() {
            this.$router.push({name: 'content-management'})
        },
        getData() {
            this.loading = true

            let content = this.state === 'add' 
                ? null 
                : new Promise((resolve, reject) => {
                    this.$http
                        .get(`${process.env.VUE_APP_API_URL}/contents/${this.id}`)
                        .then(response => {
                            resolve(response.data.data)
                        }).catch(e => reject(e))
                })

            let meta = new Promise((resolve, reject) => {
                this.$http
                    .get(`${process.env.VUE_APP_API_URL}/content-meta`)
                    .then(response => {
                        let metas = response.data.data.content_metas
                        metas.forEach(meta => {
                            if (meta.meta_name === 'promo_code') {
                                this.promo_codes.push(meta.meta_info.toLowerCase())
                            }
                        });
                        resolve(metas)
                    }).catch(e => reject(e))
            })

            Promise.all([content, meta]).then(values => {
                this.loading = false

                this.form = {...this.form, ...values[0]}
                this.form.selected_type['name'] = this.form.type
                this.form.status = 1

                let metas = values[1]
                metas.forEach(meta => {
                    if (meta.content_id === parseInt(this.id)) {
                        this.form[meta.meta_name].value = meta.meta_info
                        this.form[meta.meta_name].id = meta.id
                        this.init_promo_code = 
                            this.state === 'edit' && meta.meta_name === 'promo_code' 
                                ? meta.meta_info 
                                : this.init_promo_code
                    }
                });

                this.minDate = new Date(this.form.start_date.value)
            })
        },
        setFileUrl(url) {
            this.form.banner = url
        },
        removeEndDate() {
            this.form.end_date.value = ''
        },
        isPromoDuplicate(promo) {
            let isDuplicate = this.promo_codes.includes(promo.toLowerCase())
            return isDuplicate
        },
        doSubmit() {
            let method = this.state === 'edit' ? 'put' : 'post'
            let URI = this.state === 'add' ? 'contents' : `contents/${this.id}`

            this.form.type = this.form.selected_type.name

            if (((
                    this.state === "edit" &&
                    this.init_promo_code !== this.form.promo_code.value
                ) ||
                this.state === "add") &&
                this.form.type === "PROMO" &&
                this.isPromoDuplicate(this.form.promo_code.value)
            ) {
                this.is_duplicate_promo = true
                this.$toast.add({
                    severity:'error', 
                    summary: 'Error!', 
                    detail: 'Duplicate content!', 
                    life: 5000,
                });
                return
            }

            this.form.loading = true
            this.$http[method](`${process.env.VUE_APP_API_URL}/${URI}`, this.form)
                .then(response => {
                    this.form.loading = false

                    if (response.data.code !== 200) {
                        this.$toast.add({
                            severity:'error', 
                            summary: 'Error!', 
                            detail: response.data.message, 
                            life: 5000,
                        });
                        return
                    }

                    let promises = []
                    let content_id = this.state === 'edit' ? parseInt(this.id) : response.data.data.id
                    
                    if (this.form.type === 'PROMO') {
                        promises.push(this.submitContentMeta(
                            content_id,
                            'promo_code',
                            this.form['promo_code'].value
                        ))
                        promises.push(this.submitContentMeta(
                            content_id,
                            'start_date',
                            this.moment(this.form['start_date'].value).format('YYYY-MM-DD HH:mm:00')
                        ))
                        promises.push(this.submitContentMeta(
                            content_id,
                            'end_date',
                            this.moment(this.form['end_date'].value).format('YYYY-MM-DD HH:mm:00')
                        ))
                    }

                    Promise.all(promises).then(() => {
                        this.$toast.add({
                            severity: 'success', 
                            summary: 'Success!', 
                            detail: response.data.message, 
                            life: 5000,
                        });

                        this.$router.push({name: 'content-management'})
                    }).catch(() => {
                        this.$toast.add({
                            severity:'error', 
                            summary: 'Error!', 
                            detail: 'Duplicate content!', 
                            life: 5000,
                        });
                    })
                }).catch(e => {
                    this.$toast.add({
                        severity:'error', 
                        summary: 'Error!', 
                        detail: e.response.data.message, 
                        life: 5000,
                    });
                })
        },
        submitContentMeta(content_id, ref, value) {
            let method = this.form[ref].id ? 'put' : 'post'
            let URI = this.form[ref].id ? `content-meta/${this.form[ref].id}` : 'content-meta'
            let form = {
                content_id: content_id,
                meta_name: ref,
                meta_info: value
            }

            return new Promise((resolve, reject) => {
                this.$http[method](`${process.env.VUE_APP_API_URL}/${URI}`, form)
                    .then(response => {
                        if (response.data.code === 200) {
                            resolve(response)
                        } else {
                            reject(response)
                        }
                    }).catch(e => reject(e.response))
            })
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
